import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { ChapterTerm } from "../../types";

interface CardProps {
  url: string;
  chapter: ChapterTerm;
}

const Card = ({ url, chapter }: CardProps) => {
  return (
    <Link data-cy={`chapter-${chapter.field_chapter_number}`} to={url}>
      <div className="grid grid-cols-12 xs:block bg-white hover:ring focus:ring w-full xs:max-w-[220px] shadow-lg rounded-lg h-full">
        <GatsbyImage
          className="col-span-4 rounded-l-lg xs:rounded-none xs:rounded-t-lg"
          image={getImage(chapter.relationships.field_thumbnail.localFile)}
          imgClassName="rounded-l-lg xs:rounded-none xs:rounded-t-lg"
          alt={chapter.field_thumbnail.alt}
        />
        <div className="col-span-8">
          <h2 className="text-2xl text-white bg-blue-800 px-3 xs:px-4 py-2 rounded-tr-lg xs:rounded-none">
            Chapter {chapter.field_chapter_number}
          </h2>
          <h3 className="p-3 xs:p-4 text-neutral-700 font-bold">
            {chapter.description.value}
          </h3>
        </div>
      </div>
    </Link>
  );
};

export default Card;
